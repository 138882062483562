<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">

            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10">优惠券编号: </span> -->
              <div class="width250">
                <el-input
                  v-model="searchData.name"
                  placeholder="输入会员昵称/手机号/优惠劵名称"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">获取时间: </span>
              <div class="width300">
                <el-date-picker
                  v-model="searchData.createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleSearch"
                >
                </el-date-picker>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">使用状态: </span>
              <div class="width200">
                <el-select
                  class="width200"
                  v-model="searchData.state"
                  clearable
                  @change="handleSearch"
                >
                  <el-option
                    value=""
                    label="请选择"
                  ></el-option>
                  <el-option
                    :value="1"
                    label="未使用"
                  ></el-option>
                  <el-option
                    :value="2"
                    label="已使用"
                  ></el-option>
                  <el-option
                    :value="3"
                    label="已过期"
                  ></el-option>
                  <el-option
                    :value="4"
                    label="已作废"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                class="marginLeft10 marginBottom10"
              >查询</el-button>
            </div>

            <div class="marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="info"
                icon="el-icon-refresh-right"
                @click="handleReset"
                class="marginLeft10 marginBottom10"
              >重置</el-button>
            </div>

            <div class="marginRight10 marginBottom10">
              <el-button
                type="primary"
                size="medium"
                icon="el-icon-download"
                @click="handleExport"
                class="marginLeft10 marginBottom10"
              >导出</el-button>
            </div>

          </div>

        </div>
      </template>

    </avue-crud>

  </div>
</template>

<script>
import dayjs from "dayjs";
import { list } from "@/api/coupon/use";
import qs from "qs";
export default {
  name: "index",
  data() {
    return {
      searchData: {
        name: "",
        state: "",
        // createTime: [],
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "优惠券使用记录",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        menu: false,
        column: [
          {
            label: "会员编号",
            prop: "code",
          },
          {
            label: "会员昵称",
            prop: "nickname",
          },
          {
            label: "会员手机号",
            prop: "phone",
          },
          {
            label: "优惠劵名称",
            prop: "name",
          },
          {
            label: "使用条件",
            prop: "useConditionFullPrice",
            formatter: function (row, column, cellValue) {
              console.log("rowfff", row);
              return row.type == 1
                ? row.useConditionFullPrice == 0
                  ? "无条件"
                  : `满￥ ${cellValue}可用`
                : `${row.takePrice}`;
            },
          },
          {
            label: "获取时间",
            prop: "createTime",
          },
          {
            label: "获取方式",
            prop: "getWay",
            formatter: function (row, column, cellValue) {
              return row.getWay === 1
                ? "后台发放"
                : row.getWay === 2
                ? "优惠券购买"
                : row.getWay === 3
                ? "好友裂变"
                : row.getWay === 4
                ? "自主领取"
                : row.getWay;
            },
          },
          {
            label: "有效期",
            prop: "startTime",
            formatter: function (row, column, cellValue) {
              return `${row.startTime} ~ ${row.endTime}`;
            },
          },
          {
            label: "使用状态",
            prop: "state",
            formatter: function (row, column, cellValue) {
              return row.state == 1
                ? "未使用"
                : row.state == 2
                ? "已使用"
                : row.state == 3
                ? "已过期"
                : row.state == 4
                ? "已作废"
                : row.state;
            },
          },
        ],
      },

      dialogVisible: false,
      form: {
        id: "",
        parkFeeDeduct: "",
        otherDeduct: "",
        depositPrice: "",
      },
      formOption: {
        submitText: "确定",
        emptyBtn: false,
        labelWidth: "140",
        column: [
          {
            label: "停车费扣除（元）",
            prop: "parkFeeDeduct",
            type: "number",
            maxRows: 999999.99,
            // minRows: 0,
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入停车费",
                trigger: "blur",
              },
            ],
          },
          {
            label: "其他扣除（元）",
            prop: "otherDeduct",
            type: "number",
            // value: 1,
            maxRows: 999999.99,
            minRows: 0,
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入其他扣除",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    onLoad() {
      this.showLoading = true;
      const searchData = {
        state: this.searchData.state,
        name: this.searchData.name,
        // payChannel: this.searchData.payChannel,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      const searchForm = {
        path: this.$route.path,
        searchData: {
          ...this.searchData,
        },
      };

      if (this.searchData.createTime) {
        searchForm.searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchForm.searchData.createTimeEnd = dayjs(
          this.searchData.createTime[1]
        ).format("YYYY-MM-DD 23:59:59");
      }

      localStorage.setItem("searchForm", JSON.stringify(searchForm));
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      localStorage.removeItem("searchForm");
      // console.log('重置')
      this.searchData = {
        state: "",
        name: "",
        payChannel: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    // 重置form
    resetForm() {
      this.form = {
        id: "",
        parkFeeDeduct: "",
        otherDeduct: "",
        depositPrice: "",
      };
    },

    handleExport() {
      const searchData = {
        state: this.searchData.state,
        name: this.searchData.name,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      window.open(
        `/api/web/coupon/use/export?${qs.stringify(searchData)}`,
        "_self"
      );
    },
  },
};
</script>

<style scoped>
</style>